@font-face { font-family: "Enthusiast Behavior"; src: url("../font/Enthusiast Behavior.ttf") format("truetype"); font-display: block; }

@font-face { font-family: "Hind Light"; src: url("../font/Hind-Light.ttf") format("truetype"); font-display: block; }

@font-face { font-family: "Brandon Grotesque"; src: url("../font/Brandon Grotesque regular.otf") format("opentype"); font-display: block; }

@font-face { font-family: "Alcubierre"; src: url("../font/Alcubierre.otf") format("opentype"); font-display: block; }

.test { position: absolute; height: 400px; }

/*::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $secondary;
  border-radius: 10px;
}

::-webkit-scrollbar-track-piece {
  background: $primary
}

::-webkit-scrollbar-thumb {
  background: $secondary;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}*/
::-moz-selection { background: #000000; color: #FFDE17; }
::selection { background: #000000; color: #FFDE17; }

::-moz-selection { background: #000000; color: #FFDE17; }

body { margin: 0; overflow-x: hidden; font-family: "Brandon Grotesque"; font-size: 1.1vmax; }

@media screen and (max-width: 768px) { body { font-size: 1.75vmax; } }

a { color: #000000; }

a:hover { color: #4b4a4a; text-decoration: underline; }

hr { background-color: #FFDE17; height: 2px; width: 100%; border-top: 0; }

@media screen and (max-width: 768px) { hr { width: 100%; } }

h1 { color: #000000; text-shadow: 1px 1px 0 white; font-size: 5vmax; }

h2, h3 { color: #000000; text-shadow: 1px 1px 0 #FFDE17; }

h3, h4 { font-weight: bolder; }

.home { font-size: 1.1vmax; }

@media screen and (max-width: 768px) { .home { font-size: 1.75vmax; } }

.enthusiast-behavior { font-family: "Enthusiast Behavior"; }

.hind { font-family: "Hind Light"; }

.brandon-grotesque { font-family: "Brandon Grotesque"; }

.alcubierre { font-family: "Alcubierre"; }

.text-outline { text-shadow: 1px 1px 0 white; }

.full-width { min-width: 100%; width: 100%; }

.full-height { min-height: 100%; height: 100%; }

.auto-height { height: auto; }

.crop-width { max-width: 1200px; }

.no-padding { padding: 0 !important; margin: 0 !important; }

.content { position: relative; z-index: 3; }

.inner-content { max-width: 1200px; }

.header-img { width: 100%; }

.row-img { width: 100%; -o-object-fit: cover; object-fit: cover; }

.polaroid { width: 100%; background-color: white; -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.polaroid img { background-color: #FFDE17; width: 100%; }

.polaroid.container { text-align: center; padding: 10px 20px; }

.headshot { width: 100%; }

.footer { background-color: #FFDE17; z-index: 2; }

.logo { max-height: 75px; }

.xsmall { font-size: 0.75vmax; }

.small { font-size: 1vw; }

.medium { font-size: 2vmax; }

.medium-w { font-size: 2vw; }

.large { font-size: 3vmax; }

.xlarge { font-size: 4vw; }

.white-bg { background-color: white; }

.primary-color { background-color: #FFDE17; color: #000000 !important; }

.primary-color-text { color: #FFDE17 !important; }

a.primary-color-text:hover { color: #e2cd0c !important; }

.secondary-color { background-color: #000000; color: #FFDE17 !important; }

.secondary-color-text { color: #000000 !important; }

a.secondary-color-text:hover { color: #000000 !important; }

.navbar { -webkit-transition: 0.4s; transition: 0.4s; top: 0; width: 100%; position: fixed; z-index: 5; font-size: 1.5vh; }

@media screen and (max-width: 768px) { .navbar { font-size: 3vh; } }

.nav-link { -webkit-transition: 0.2s; transition: 0.2s; color: #000000; text-align: center; }

.nav-link:hover { color: #4b4a4a; text-decoration: underline; }

.dropdown-menu { background-color: #e2cd0c; }

.icon-bar { width: 22px; height: 2px; background-color: #000000; display: block; -webkit-transition: all 0.2s; transition: all 0.2s; margin-top: 4px; }

.navbar-toggler { border: none; background: transparent !important; }

.navbar-toggler .top-bar { -webkit-transform: rotate(45deg); transform: rotate(45deg); -webkit-transform-origin: 10% 10%; transform-origin: 10% 10%; }

.navbar-toggler .middle-bar { opacity: 0; }

.navbar-toggler .bottom-bar { -webkit-transform: rotate(-45deg); transform: rotate(-45deg); -webkit-transform-origin: 10% 90%; transform-origin: 10% 90%; }

.navbar-toggler.collapsed .top-bar { -webkit-transform: rotate(0); transform: rotate(0); }

.navbar-toggler.collapsed .middle-bar { opacity: 1; }

.navbar-toggler.collapsed .bottom-bar { -webkit-transform: rotate(0); transform: rotate(0); }

.header-container { background-color: #FFDE17; height: 100vh; }

.header-container img { -o-object-fit: contain; object-fit: contain; width: 100%; height: 100%; }

@media screen and (max-width: 768px) { .header-container img { width: 75%; } }

.header-container-sm { background-color: #FFDE17; z-index: -1; height: 40vh; position: -webkit-sticky; position: sticky; top: 0; }

.header-container-sm img { -o-object-fit: contain; object-fit: contain; width: 100%; height: 100%; }

.input-group-text { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; width: 38px; background-color: #FFDE17; border: 1px solid #FFDE17; color: #000000; }

.map { width: 100%; height: 100%; min-height: 250px; max-height: 350px; border: 2px solid #FFDE17; }

form.contact { width: 100%; }

.footer-insta:hover { -webkit-transition: 0.4s; transition: 0.4s; color: #cf426d !important; }

.footer-facebook:hover { -webkit-transition: 0.4s; transition: 0.4s; color: #3877ea !important; }

.btn:hover { background-color: #e2cd0c; }

.img-wrap { width: 70%; max-height: 300px; -o-object-fit: cover; object-fit: cover; float: right; }

@media screen and (max-width: 768px) { .img-wrap { width: 100%; } }

.service-img { width: 100%; -o-object-fit: cover; object-fit: cover; }

.testimonials { width: 75%; }

@media screen and (max-width: 768px) { .testimonials { width: 100%; } }

.swiper-container { height: 20vh; }

@media screen and (max-width: 768px) { .swiper-container { height: 40vh; } }

.swiper-slide { padding: 0px 20px 0px 20px; width: 100% !important; }

.swiper-slide p, .swiper-slide h3 { text-align: center; }

.swiper-button-next, .swiper-button-prev { color: #FFDE17 !important; }

.swiper-pagination-bullet-active { background-color: #FFDE17 !important; }
