$primary: #FFDE17;
$primary-dark: #e2cd0c;
$secondary-light: #4b4a4a;
$secondary: #000000;
$secondary-dark: #000000;

@font-face {
  font-family: "Enthusiast Behavior";
  src: url("../font/Enthusiast\ Behavior.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Hind Light";
  src: url("../font/Hind-Light.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("../font/Brandon\ Grotesque\ regular.otf") format("opentype");
  font-display: block;
}

@font-face {
  font-family: "Alcubierre";
  src: url("../font/Alcubierre.otf") format("opentype");
  font-display: block;
}