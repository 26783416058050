@import "variables";

.test {
  position: absolute;
  height: 400px;
}

/*::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $secondary;
  border-radius: 10px;
}

::-webkit-scrollbar-track-piece {
  background: $primary
}

::-webkit-scrollbar-thumb {
  background: $secondary;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}*/

::selection {
  background: $secondary;
  color: $primary;
}

::-moz-selection {
  background: $secondary;
  color: $primary;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Brandon Grotesque";
  font-size: 1.1vmax;

  @media screen and (max-width: 768px) {
    font-size: 1.75vmax;
  }
}

a {
  color: $secondary;

  &:hover {
    color: $secondary-light;
    text-decoration: underline;
  }
}

hr {
  background-color: $primary;
  height: 2px;
  width: 100%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  border-top: 0;
}

h1 {
  color: $secondary;
  text-shadow: 1px 1px 0 white;
  font-size: 5vmax;
}

h2,
h3 {
  color: $secondary;
  text-shadow: 1px 1px 0 $primary;
}

h3,
h4 {
  font-weight: bolder;
}

.home {
  font-size: 1.1vmax;

  @media screen and (max-width: 768px) {
    font-size: 1.75vmax;
  }
}

.enthusiast-behavior {
  font-family: "Enthusiast Behavior";
}

.hind {
  font-family: "Hind Light";
}

.brandon-grotesque {
  font-family: "Brandon Grotesque";
}

.alcubierre {
  font-family: "Alcubierre";
}

.text-outline {
  text-shadow: 1px 1px 0 white;
}

.full-width {
  min-width: 100%;
  width: 100%;
}

.full-height {
  min-height: 100%;
  height: 100%;
}

.auto-height {
  height: auto;
}

.crop-width {
  max-width: 1200px;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.content {
  position: relative;
  z-index: 3;
}

.inner-content {
  max-width: 1200px;
}

.header-img {
  width: 100%;
}

.row-img {
  width: 100%;
  object-fit: cover;
}

.polaroid {
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.polaroid img {
  background-color: $primary;
  width: 100%;
}

.polaroid.container {
  text-align: center;
  padding: 10px 20px;
}

.headshot {
  width: 100%;
}

.footer {
  background-color: $primary;
  z-index: 2;
}

.logo {
  max-height: 75px;
}

.xsmall {
  font-size: 0.75vmax;
}

.small {
  font-size: 1vw;
}

.medium {
  font-size: 2vmax;
}

.medium-w {
  font-size: 2vw;
}

.large {
  font-size: 3vmax;
}

.xlarge {
  font-size: 4vw;
}

.white-bg {
  background-color: white;
}

.primary-color {
  background-color: $primary;
  color: $secondary !important;
}

.primary-color-text {
  color: $primary !important;
}

a.primary-color-text:hover {
  color: $primary-dark !important;
}

.secondary-color {
  background-color: $secondary;
  color: $primary !important;
}

.secondary-color-text {
  color: $secondary !important;
}

a.secondary-color-text:hover {
  color: $secondary-dark !important;
}

.navbar {
  transition: 0.4s;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
  font-size: 1.5vh;

  @media screen and (max-width: 768px) {
    font-size: 3vh;
  }
}

.nav-link {
  transition: 0.2s;
  color: $secondary;
  text-align: center;

  &:hover {
    color: $secondary-light;
    text-decoration: underline;
  }
}

.dropdown-menu {
  background-color: $primary-dark;
}

.icon-bar {
  width: 22px;
  height: 2px;
  background-color: $secondary;
  display: block;
  transition: all 0.2s;
  margin-top: 4px;
}

.navbar-toggler {
  border: none;
  background: transparent !important;
}

.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
}

.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}

.header-container {
  background-color: $primary;
  height: 100vh;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
      width: 75%;
    }
  }
}

.header-container-sm {
  background-color: $primary;
  z-index: -1;
  height: 40vh;
  position: sticky;
  top: 0;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.input-group-text {
  display: flex;
  justify-content: center;
  width: 38px;
  background-color: $primary;
  border: 1px solid $primary;
  color: $secondary;
}

.map {
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-height: 350px;
  border: 2px solid $primary;
}

form.contact {
  width: 100%;
}

.footer-insta:hover {
  transition: 0.4s;
  color: #cf426d !important;
}

.footer-facebook:hover {
  transition: 0.4s;
  color: #3877ea !important;
}

.btn {
  &:hover {
    background-color: $primary-dark;
  }
}

.img-wrap {
  width: 70%;
  max-height: 300px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  object-fit: cover;
  float: right;
}

.service-img {
  width: 100%;
  object-fit: cover;
}

.testimonials {
  width: 75%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.swiper-container {
  //width: 100%;
  height: 20vh;

  @media screen and (max-width: 768px) {
    height: 40vh;
  }
}

.swiper-slide {
  padding: 0px 20px 0px 20px;
  width: 100% !important;
}

.swiper-slide p,
.swiper-slide h3 {
  text-align: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: $primary !important;
}

.swiper-pagination-bullet-active {
  background-color: $primary !important;
}
